import { Framework } from '@/mesh/web-framework'
import '@/mesh/web-framework/dist/style.css'

const framework = new Framework()

import { productRoutes, rootRoutes, redirects, homePath } from '@/configs/routes'
framework.register('productRoutes', 'ezsmartcloud', productRoutes)
framework.register('rootRoutes', rootRoutes)
framework.register('redirects', redirects)
framework.register('homePath', homePath)

import HostNavigator from '@/components/navigator/index.vue'

import { usePresets } from '@/mesh/presets-ubang'
import '@/mesh/presets-ubang/dist/style.css'
usePresets(framework)

framework.set('navigator', {position: 'fixed', height: '64px'})
framework.register('component', 'HostNavigator', HostNavigator)

framework.start({
  mount: { mountId: '#app' },
  project: {}
})
