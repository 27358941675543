export const productRoutes = [
  {
    path: 'home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/pages/home/index.vue')
  },
  // {
  //   path: 'product',
  //   name: 'Product',
  //   component: () => import(/* webpackChunkName: "product" */ '@/views/pages/product/index.vue')
  // },
  {
    path: 'microsoft',
    name: 'MicrosoftProduct',
    component: () => import(/* webpackChunkName: "microsoft-product" */ '@/views/pages/product/microsoft.vue')
  },
  {
    path: 'multi-manage-platform',
    name: 'MultiManagePlatform',
    component: () => import(/* webpackChunkName: "multi-manage-platform" */ '@/views/pages/product/multi-manage-platform.vue')
  },
  // {
  //   path: 'product2',
  //   name: 'Product2',
  //   component: () => import(/* webpackChunkName: "product" */ '@/views/pages/product/index2.vue')
  // },
  {
    path: 'service',
    name: 'Service',
    component: () => import(/* webpackChunkName: "service" */ '@/views/pages/service/index.vue')
  },
  {
    path: 'solution',
    name: 'Solution',
    component: () => import(/* webpackChunkName: "solution" */ '@/views/pages/solution/index.vue')
  },
  {
    path: 'about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '@/views/pages/about/index.vue')
  },
]

export const rootRoutes = []

export const redirects = [
  {
    path: '/ezsmartcloud/',
    redirect: '/ezsmartcloud/home',
  },
  {
    path: '/',
    redirect: '/ezsmartcloud/home',
  },
]
export const homePath = '/ezsmartcloud'
