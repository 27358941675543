<template>
  <div class="navigator">
    <HostLayoutContainer :style="{ height: '100%', 'align-items': 'center' }">
      <HostLayoutItem>
        <div class="navigator__logo">
          <img src="/logo@3x.svg" alt="" />
        </div>
        <div class="menu">
          <a-menu
            mode="horizontal"
            v-model:selectedKeys="selectedKeys"
            @click="changeKeys"
          >
            <template v-for="link in links" :key="link.key">
              <template v-if="link.children && link.children.length">
                <a-sub-menu :key="link.key">
                  <template #title>{{ link.text }}</template>
                  <a-menu-item v-for="link2 in link.children" :key="link2.key">
                    {{ link2.text }}
                  </a-menu-item>
                </a-sub-menu>
              </template>
              <template v-else>
                <a-menu-item :key="link.key"> {{ link.text }} </a-menu-item>
              </template>
            </template>
          </a-menu>
        </div>
      </HostLayoutItem>
    </HostLayoutContainer>
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  name: "HostNavigator",
  setup () {
    const router = useRouter()
    const route = useRoute()
    const links = [
      { key: 'Home', path: '/home', text: '首页' },
      { key: 'Product', path: '/product', text: '主要产品', children: [
        { key: 'MicrosoftProduct', path: '/product/microsoft' , text: 'Microsoft 产品'},
        { key: 'MultiManagePlatform', path: '/product/multi-manage-platform', text: '多云管理平台'},
      ]},
      { key: 'Service', path: '/service', text: '云服务' },
      { key: 'Solution', path: '/solution', text: '解决方案' },
      { key: 'About', path: '/about', text: '关于我们' }
    ]

    const selectedKeys = ref([route.name])
    const changeKeys = (item) => {
      router.push({ name: item.key })
    }
    watch(() => route.name, (current, pre) => {
      selectedKeys.value[0] = current
    })
    return {
      links,
      selectedKeys,
      changeKeys,
    }
  }
})
</script>

<style lang="scss" scoped>
.navigator {
  //height: 100%;
  height: 64px;
  background: linear-gradient(180deg, #a2b5cb 0%, rgba(24, 79, 136, 0.3) 100%);
}
.navigator__logo {
  width: 145px;
  img {
    height: auto;
  }
}
::v-deep(.layout) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
::v-deep(.menu .ant-menu) {
  background-color: transparent;
  color: #fff;
  border: none;
}
::v-deep(.menu .ant-menu-horizontal) {
  line-height: 60px;
}
</style>
